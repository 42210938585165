//
//
//
//
//
//
//
//
//
//
//

//tab对应的组件
import Headers from "@/components/Headers.vue"
export default {
  data(){
    return {
      taglist : [
        {
          path : '/main/report/order',
          name : '报障工单',
          style : 'transform: translateX(0);;z-index : 4;'
        },
        {
          path : '/main/report/orderapply',
          name : '报障申请',
          style : 'transform : translateX(-10px);z-index : 3;'
        },
        {
          path : '/main/report/appapply',
          name : '设备申请',
          style : 'transform : translateX(-20px);z-index : 2;'
        }
      ]
    }
  },
  components : {
    Headers
  }
}
